import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [profile, setProfile] = useState(null); // Set the initial value

    return (<AuthContext.Provider value={{ profile, setProfile }}>
        {children}
    </AuthContext.Provider>);
};

export const useAuth = () => useContext(AuthContext);
