import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const storedLanguage = localStorage.getItem("agricircle_language");
const initialLanguage = storedLanguage || "en"; // Default to "en" if not set

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        //debug: true,
        load: "languageOnly",
        lng: initialLanguage,
        fallbackLng: "en",
        backend: {
            // Customize the path to your language resources
            loadPath: "locales/{{lng}}/{{ns}}.json"
        }
    });
